const defaultBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': Infinity,
}

export default function mixinsCheckBreakpoints(breakpoints = {}) {
  return {
    data() {
      return {
        currentBreakpoint: '',
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize() {
        const clientWidth = window.innerWidth
        if (clientWidth <= this._breakpoints.sm) {
          this.currentBreakpoint = 'sm'
        } else if (clientWidth <= this._breakpoints.md) {
          this.currentBreakpoint = 'md'
        } else if (clientWidth <= this._breakpoints.lg) {
          this.currentBreakpoint = 'lg'
        } else if (clientWidth <= this._breakpoints.xl) {
          this.currentBreakpoint = 'xl'
        }else if (clientWidth <= this._breakpoints['2xl']) {
          this.currentBreakpoint = '2xl'
        }else if (clientWidth <= this._breakpoints['3xl']) {
          this.currentBreakpoint = '3xl'
        }
      },
    },
    computed: {
      _breakpoints()
      {
        return { ...defaultBreakpoints, ...breakpoints }
      },
    },
  }
}