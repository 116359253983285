<!-- INFO: support all v-chakra binding property-->

<template>
  <!--  <c-box>-->
  <!--    <input type="text" v-model="valuee">-->
  <!--    <input type="text" v-model="animationDelay">-->

  <c-box
    :h="STYLES[size].height"
    rounded="1000px"
    v-bind="$attrs"
    width="100%"
    :background-color="backgroundColor"
    :border="border"
    v-on="$listeners"
  >
    <c-box
      rounded="1000px"
      height="115%"
      background-color="#005A64"
      :width="valuee + '%'"
      overflow="hidden"
      white-space="nowrap"
      position="relative"
      display="inline-block"
      font-family="Roboto"
      top="50%"
      transform="translateY(-50%)"
      :font-size="STYLES[size].fontSize"
      :font-weight="STYLES[size].fontWeight"
    >
      <c-text
        color="#C7F9E3"
        overflow="hidden"
        white-space="nowrap"
        text-overflow="ellipsis"
        width="calc(100% - 80px)"
        display="inline-block"
        position="absolute"
        top="50%"
        left="30px"
        transform="translateY(-50%)"
      >
        {{ text }}
      </c-text>

      <c-text
        color="#C7F9E3"
        display="inline-block"
        position="absolute"
        top="50%"
        right="12px"
        transform="translateY(-50%)"
        font-size="12px"
      >
        {{ value }}%
      </c-text>
    </c-box>
  </c-box>
<!--  </c-box>-->
</template>

<script>
const STYLES = {
  // small: {
  //   height: '8px',
  // },
  medium: {
    height: ['41px', '47px'],
    fontWeight: '500',
    fontSize: ['14px', '18px'],
  },
  // large: {
  //   height: '24px',
  // },
}

// import BaseText from '@/components/elements/base-text.vue'

export default {
  name: 'BaseProgressBar',
  components: {
    // BaseText
  },
  inheritAttrs: false,
  props: {
    initialValue: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [String, Number],
      required: true,
    },
    delay: {
      type: [String, Number],
      default: '0',
    },
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return [
          // 'small',
          'medium',
          // 'large',
        ].includes(value)
      },
    },
    backgroundColor: {
      type: String,
      default: '#C4C4C4',
    },
    border: {
      type: [String, null],
      default: null, 
    },
  },
  data() {
    return {
      valuee: '100',
      STYLES,
    }
  },
  mounted() {
    this.valuee = this.initialValue || this.value
    setTimeout(() => {
      this.valuee = this.value
    })
  },
}
</script>
