<template>
  <c-box
    :background-color="['#F2F2F2', '#FFF']"
    padding-bottom="16px"
    width="100%"
  >
    <c-box
      position="relative"
      mx="auto"
      :background-color="['#F2F2F2', '#FFF']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :min-height="['calc(100vh - 62px)', '74vh']"
      width="100%"
      max-width="1270px"
    >
      <!-- DIETELA TERBUKTI -->
      <c-box :padding="['0 0 0 0', '16px 16px 0 16px']">
        <c-box
          :padding="['32px 20px', '32px 83px']"
          display="flex"
          :flex-direction="['column', 'row']"
          :gap="['8px', '70px']"
          justify-content="center"
          align-items="center"
          :border-radius="['0','12px']"
          position="relative"
          overflow="hidden"
        >
          <c-image
            position="absolute"
            src="https://ik.imagekit.io/dietela/pwa_webp/extend_program/bg_extendpage.webp?updatedAt=1686540820606"
            width="100%"
            height="100%"
            object-fit="cover"
            transform="scale(1.4)"
            :opacity="['0.2', '0.4']"
          />
          <c-box
            display="flex"
            flex-direction="column"
            :justify-content="['center']"
            :align-items="['stretch', 'flex-start']"
            position="relative"
          >
            <BaseText
              size-mobile="18px-2"
              size-desktop="36px-2"
              color="#005A64"
              font-weight="900"
              white-space="nowrap"
              :text-align="['center', 'left']"
            >
              LANJUTKAN
              <br>
              PROGRAM DIETELA
              <br>
              HANYA 8000an/HARI
            </BaseText>

            <c-box
              position="relative"
              margin-bottom="3px"
              width="150%"
              left="50%"
              transform="translateX(-50%)"
              :display="['block', 'none']"
            >
              <BaseDivider
                border-color="#005A64"
              />
            </c-box>

            <BaseButton
              v-chakra="{
                color: '#008C81 !important',
                fontWeight: '600',
              }"
              size="medium"
              border-radius="1000px"
              padding="0px 32px"
              :margin-top="['0', '16px']"
              variant="solid"
              color="secondary"
              background-color="secondary.400"
              @click="handleLearnMore"
            >
              Pelajari lebih lanjut
            </BaseButton>
          </c-box>
          <c-box
            width="100%"
            position="relative"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="36px"
              text-align="center"
              color="primary.500"
              border-bottom="1px solid #005A64"
            >
              Dietela Terbukti
            </BaseText>

            <c-box
              :margin-top="['10px', '20px']"
            >
              <c-box
                v-for="(dataHeader, dataHeaderIdx) in listDataHeader"
                :key="dataHeaderIdx"
                :margin-top="dataHeaderIdx === 0 ? '0' : '16px'"
              >
                <BaseText
                  size-mobile="14px"
                  size-desktop="18px"
                >
                  {{ dataHeader.label }}
                </BaseText>
                <BaseProgressBar
                  margin-top="8px"
                  initial-value="0"
                  :value="dataHeader.value * 100"
                  :delay="dataHeaderIdx * 0.1"
                  :text="dataHeader.description"
                  background-color="#F2F2F2"
                  border="1px solid #888"
                />
              </c-box>
            </c-box>
          </c-box>
        </c-box>
      </c-box>

      <!-- KENAPA LANJUTKAN LAYANAN? -->
      <c-box :padding="['16px 8px', '16px 16px 0 16px']">
        <c-box
          background-color="#FFFCDD"
          pos="relative"
          d="flex"
          border-top-left-radius="4px"
          border-top-right-radius="4px"
          :border-bottom-left-radius="['20px', '100px']"
          :border-bottom-right-radius="['10px', '40px']"
          overflow="hidden"
        >
          <c-box
            pos="absolute"
            background-color="#FDF9BC"
            border-radius="100%"
            h="500px"
            w="500px"
            :top="['-250px', 'unset']"
            :bottom="['unset', '2.5rem']"
            :left="['70px', '5rem']"
            z-index="1"
          />
          <c-box
            d="flex"
            max-width="920px"
            :py="['1rem', '1.5rem']"
            :px="['1rem', '0']"
            mx="auto"
            z-index="2"
          >
            <c-image
              :d="['none', 'block']"
              src="https://ik.imagekit.io/dietela/pwa_webp/extend_program/lanjutkanprogram.webp?updatedAt=1686540820023"
              h="250px"
              flex-shrink="0"
              mr="1rem"
            />
            <c-flex
              flex-grow="1"
              flex-direction="column"
              justify-content="center"
            >
              <c-text
                :font-size="['18px', '28px']"
                font-weight="700"
                color="#008C81"
                mb="1rem"
                :text-align="['center', 'left']"
              >
                KENAPA LANJUTKAN LAYANAN?
              </c-text>
              <c-image
                :d="['block', 'none']"
                src="https://ik.imagekit.io/dietela/pwa_webp/extend_program/lanjutkanprogram.webp?updatedAt=1686540820023"
                h="150px"
                mb="1rem"
                mx="auto"
              />
              <c-text
                :font-size="['14px', '16px']"
                font-weight="400"
                mb="1rem"
              >
                <c-box
                  as="span"
                  :font-weight="['600', '700']"
                >
                  Support system personal.
                </c-box> Penelitian menunjukkan bahwa dukungan dari tenaga kesehatan adalah salah satu faktor terpenting dalam mempertahankan hasil diet, untuk mencegah kamu kembali ke kondisi sebelumnya.
              </c-text>
              <c-text
                :font-size="['14px', '16px']"
                font-weight="400"
              >
                <c-box
                  as="span"
                  :font-weight="['600', '700']"
                >
                  Harga khusus, fasilitas banyak.
                </c-box> Sekali kamu berlangganan lebih dari 1 bulan, kamu akan mendapatkan harga khusus dengan fasilitas layanan Ahli Gizi, olahraga dan webinar Dietela.
              </c-text>
            </c-flex>
          </c-box>
        </c-box>
      </c-box>

      <c-box
        v-if="programExtends.length > 0"
        id="comparation"
        width="100%"
        max-width="1100px"
        :px="['10px', '0']"
        background-color="#FFFFFF"
        :py="['10px', '20px']"
        mx="auto"
        class="tableGsap"
      >      
        <BaseText
          size-mobile="18px"
          size-desktop="28px"
          text-align="center"
          :margin-bottom="['10px', '20px']"
        >
          Pilih Program Sesuai Kebutuhanmu
        </BaseText>

        <c-box
          pos="relative"
          :display="['none', 'table']"
          as="table"
          width="100%"
          class="table-comparation"
          z-index="3"
        >
          <c-box
            as="thead"
            color="#FFF"
            background-color="primary.400"
            font-weight="700"
            border-radius="8px"
          >
            <c-box
              v-chakra="{
                '>:first-child': {
                  borderLeft: '1px solid #008C81',
                },
                '>:last-child': {
                  borderRight: '1px solid #008C81',
                },
              }"
              as="tr"
            >
              <c-box
                as="th"
                text-align="center"
                font-size="24px"
                py="1rem"
              >
                Fasilitas Layanan
              </c-box>
              <c-box
                v-for="item in programExtends"
                :key="item.id"
                as="th"
                flex-direction="column"
                justify="center"
                py="1rem"
                width="315px"
              >
                <c-box
                  border-left="1px solid #005A64"
                  px="8px"
                >
                  <c-text
                    font-size="20px"
                    margin-bottom="8px"
                    font-weight="700"
                  >
                    {{ item?.programsService }} {{ item?.duration }} Hari
                  </c-text>
                  <c-box
                    display="flex"
                    justify-content="center"
                    align-items="center"
                    gap="10px"
                    border-radius="8px"
                    background-color="primary.100"
                  >
                    <c-text
                      font-size="16px"
                      font-weight="700"
                      text-decoration="line-through"
                      color="danger.400"
                    >
                      {{ formatCurrency(item.price, 2) }}
                    </c-text>
                    <c-text
                      font-size="32px"
                      font-weight="700"
                      color="primary.600"
                    >
                      {{ formatCurrency(item.discountedPrice, 2) }}
                    </c-text>
                  </c-box>
                  <c-box
                    font-size="16px"
                    font-weight="400"
                    mt="8px"
                  >
                    ({{ formatCurrency(pricePerDay(item.discountedPrice, item.duration), 2) }}/hari)
                  </c-box>
                </c-box>
              </c-box>
            </c-box>
          </c-box>
          <c-box
            v-chakra="{
              '> tr': {
                borderLeft: '1px solid #008C81',
                borderRight: '1px solid #008C81',
              },
            }"
            as="tbody"
            background-color="#FFF"
            color="#000"
            :font-size="['12px', '16px']"
          >
            <c-box
              v-for="(facility, i) in facilities"
              :key="`tr-${i}`"
              v-chakra="{
                '>:not(:last-child):not(:first-child)': {
                  borderRight: '1px solid #C4C4C4',
                  borderLeft: '1px solid #C4C4C4',
                },
              }"
              as="tr"
            >
              <c-box
                v-for="k in (programExtends.length + 1)"
                :key="`td-${i}-${k}`"
                as="td"
                px="1rem"
                :text-align="k === 1 ? 'left' : 'center'"
              >
                <c-box
                  v-if="k === 1"
                  w="100%"
                  py="1rem"
                  :px="k === 1 ? '12px' : '0'"
                  border-bottom="1px solid #C4C4C4"
                >
                  {{ facility }}
                </c-box>
                <c-box
                  v-else
                  w="100%"
                  py="1rem"
                  border-bottom="1px solid #C4C4C4"
                  display="flex"
                  justify-content="center"
                >
                  <inline-svg
                    v-if="isCheckList(getFacilityValue(facility, programExtends[k-2]?.productFacilitiesComparation))"
                    :src="checkIcon(getFacilityValue(facility, programExtends[k-2]?.productFacilitiesComparation)) ? iconCircleCheck : iconCircleClose"
                    class="icon-list"
                    :fill="checkIcon(getFacilityValue(facility, programExtends[k-2]?.productFacilitiesComparation)) ? '#008C81' : '#D32737'"
                  />
                  <c-box
                    v-else
                  >
                    {{ getFacilityValue(facility, programExtends[k-2]?.productFacilitiesComparation) }}
                  </c-box>
                </c-box>
              </c-box>
            </c-box>
            <c-box
              v-chakra="{
                '>:not(:last-child):not(:first-child)': {
                  borderRight: '1px solid #C4C4C4',
                  borderLeft: '1px solid #C4C4C4',
                },
              }"
              as="tr"
              border-bottom="1px solid #008C81"
            >
              <c-box 
                as="td"
                text-align="center"
              />
              <c-box
                v-for="(item, index) in programExtends"
                :key="`buy-${item?.id}`"
                as="td"
                px="1rem"
                py="1rem"
              >
                <c-flex
                  justify-content="space-between"
                  align-items="center"
                  gap="12px"
                >
                  <c-button
                    variant="outline"
                    variant-color="primary"
                    height="50px"
                    min-width="50px"
                    padding="0px"
                    border-radius="12px"
                    :is-loading="isLoading.addToCart"
                    @click="handleAddToCart(item, index)"
                  >
                    <inline-svg
                      :src="require('@/assets/icons/icon-cart.svg')"
                      height="20px"
                      width="20px"
                      fill="#008C81"
                    />
                  </c-button>
                  <c-button
                    v-chakra="{
                      ':hover': {
                        color:'primary.500',
                        background:'secondary.500',
                      },
                      ':active': {
                        color: 'white',
                        background: 'secondary.600',
                      },
                    }"
                    width="100%"
                    variant="solid"
                    color="primary.400"
                    background="secondary.400"
                    border-radius="60px"
                    height="50px"
                    :is-loading="isLoading.buyNow || isSubmittingCheckOrder"
                    @click="handleCheckOrder(item, index)"
                  >
                    Beli Sekarang
                  </c-button>
                </c-flex>
              </c-box>
            </c-box>
          </c-box>
        </c-box>

        <c-box
          pos="relative"
          :display="['block', 'none']"
          z-index="3"
          width="100%"
        >
          <c-grid
            v-chakra="{
              '> :not(:first-child) > div': {
                borderLeft:'1px solid #005A64',
              },
            }"
            w="100%"
            :template-columns="`repeat(${programExtends.length}, 1fr)`"
            background-color="primary.400"
            color="#FFF"
            font-size="14px"
            font-weight="500"
            border-top-left-radius="8px"
            border-top-right-radius="8px"
            border="1px solid #008C81"
          >
            <c-box
              v-for="(item) in programExtends"
              :key="item?.programsService"
              w="100%"
              py="6px"
            >
              <c-flex
                w="100%"
                flex-direction="column"
                align-items="center"
                px="8px"
              >
                <c-text
                  font-size="14px"
                  margin-bottom="8px"
                  font-weight="500"
                  text-align="center"
                >
                  {{ item?.programsService }} {{ item?.duration }} Hari
                </c-text>
                <c-box
                  width="100%"
                  display="flex"
                  flex-direction="column"
                  justify-content="center"
                  align-items="center"
                  gap="0px"
                  border-radius="8px"
                  background-color="primary.100"
                  padding-top="4px"
                >
                  <c-text
                    font-size="12px"
                    font-weight="700"
                    text-decoration="line-through"
                    color="danger.400"
                  >
                    {{ formatCurrency(item.price, 2) }}
                  </c-text>
                  <c-text
                    font-size="20px"
                    font-weight="700"
                    color="primary.600"
                  >
                    {{ formatCurrency(item.discountedPrice, 2) }}
                  </c-text>
                </c-box>
                <c-box
                  font-size="10px"
                  font-weight="400"
                  mt="4px"
                >
                  ({{ formatCurrency(pricePerDay(item.discountedPrice, item.duration), 2) }}/hari)
                </c-box>
              </c-flex>
            </c-box>
          </c-grid>
          <c-box
            p="8px"
            color="#000"
            font-size="12px"
            font-weight="400"
            background-color="#FFF"
            border-bottom-left-radius="8px"
            border-bottom-right-radius="8px"
            border-left="1px solid #008C81"
            border-right="1px solid #008C81"
            border-bottom="1px solid #008C81"
          >
            <c-box
              v-for="(facility, index) in facilities"
              :key="`facility-${index}`"
              mb="8px"
            >
              <c-box
                p="6px 12px"
                background-color="primary.50"
                text-align="center"
              >
                {{ facility }}
              </c-box>
              <c-grid
                v-chakra="{
                  '> :not(:first-child)': {
                    borderLeft:'1px solid #C4C4C4',
                  },
                }"
                w="100%"
                :template-columns="`repeat(${programExtends.length}, 1fr)`"
              >
                <c-flex
                  v-for="j in programExtends.length"
                  :key="j"
                  w="100%"
                  py="4px"
                  flex-direction="column"
                  align-items="center"
                >
                  <inline-svg
                    v-if="isCheckList(getFacilityValue(facility, programExtends[j-1]?.productFacilitiesComparation))"
                    :src="checkIcon(getFacilityValue(facility, programExtends[j-1]?.productFacilitiesComparation)) ? iconCircleCheck : iconCircleClose"
                    class="icon-list"
                    :fill="checkIcon(getFacilityValue(facility, programExtends[j-1]?.productFacilitiesComparation)) ? '#008C81' : '#D32737'"
                  />
                  <c-box
                    v-else
                  >
                    {{ getFacilityValue(facility, programExtends[j-1]?.productFacilitiesComparation) }}
                  </c-box>
                </c-flex>
              </c-grid>
            </c-box>

            <c-grid
              v-chakra="{
                '> :not(:first-child)': {
                  borderLeft:'1px solid #C4C4C4',
                },
              }"
              w="100%"
              :template-columns="`repeat(${programExtends.length}, 1fr)`"
            >
              <c-flex
                v-for="(item, index) in programExtends"
                :key="index"
                width="100%"
                justify-content="space-between"
                align-items="center"
                p="6px"
                gap="6px"
              >
                <c-button
                  variant="outline"
                  variant-color="primary"
                  height="34px"
                  min-width="34px"
                  padding="0px"
                  border-radius="12px"
                  :is-loading="isLoading.addToCart"
                  @click="handleAddToCart(item, index)"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-cart.svg')"
                    height="18px"
                    width="18px"
                    fill="#008C81"
                  />
                </c-button>
                <c-button
                  v-chakra="{
                    ':hover': {
                      color:'primary.500',
                      background:'secondary.500',
                    },
                    ':active': {
                      color: 'white',
                      background: 'secondary.600',
                    },
                  }"
                  flex-grow="1"
                  width="100%"
                  variant="solid"
                  color="primary.400"
                  background="secondary.400"
                  border-radius="16px"
                  height="34px"
                  font-size="12px"
                  px="0"
                  :is-loading="isLoading.buyNow || isSubmittingCheckOrder"
                  @click="handleCheckOrder(item, index)"
                >
                  Beli Sekarang
                </c-button>
              </c-flex>
            </c-grid>
          </c-box>
        </c-box>
      </c-box>

      <!-- Pilih Program Sesuai Kebutuhanmu -->
      <!-- <c-box
        id="tes"
        :padding="['16px 0 0 0', '16px']"
        display="flex"
        flex-direction="column"
        align-items="center"
      >
        <c-box
          :border="['none', '1px solid #008C81']"
          :padding="['20px']"
          background-color="white"
          width="100%"
          max-width="900px"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="28px"
            text-align="center"
          >
            Pilih Program Sesuai Kebutuhanmu
          </BaseText>
          <c-grid
            :width="['100%', '100%']"
            display="flex"
            :flex-direction="['column', 'row']"
            :gap="['1rem', '1rem']"
            margin-top="16px"
            justify-content="center"
            mx="auto"
          >
            <c-box
              v-for="(item, index) in programGrouping"
              :key="index"
              :w="['100%', '100%']"
              :max-width="['100%', '420px']"
              border-radius="12px"
              border="1px solid #008C81"
              p="8px"
              background-color="white"
            >
              <c-box
                rounded-bottom="12px"
                background-color="#C7F9E3"
                :p="['8px', '12px']"
                mb="8px"
                pos="relative"
                overflow="hidden"
              >
                <c-text
                  :font-size="['16px', '28px']"
                  color="#111111"
                  align="center"
                  mb="4px"
                  font-weight="700"
                  font-family="Roboto"
                >
                  {{ item.programsService }}
                </c-text>
                <c-text
                  :font-size="['16px', '18px']"
                  color="#111111"
                  align="center"
                  font-weight="500"
                  font-family="Roboto"
                >
                  {{ item.duration }}  Hari
                </c-text>
                <c-text
                  :font-size="['12px', '14px']"
                  color="#111111"
                  align="center"
                  font-weight="400"
                  font-family="Roboto"
                >
                  {{ item.description }}
                </c-text>
              </c-box>
              <c-text
                :font-size="['32px']"
                font-weight="bold"
                align="center"
                mb="8px"
                font-family="Roboto"
                color="#111111"
              >
                <c-box
                  display="flex"
                  justify-content="center"
                  align-items="center"
                  gap="16px"
                >
                  <BaseText
                    size-mobile="16px-2"
                    size-desktop="16px-2"
                    text-decoration="line-through"
                    color="#D32737"
                  >
                    {{ formatCurrency(item.price, 2) }}
                  </BaseText>
                  <BaseText
                    size-mobile="36px"
                    size-desktop="36px"
                  >
                    {{ formatCurrency(item.discountedPrice, 2) }}
                  </BaseText>
                </c-box>
                <c-box
                  font-size="16px"
                  font-weight="400"
                  mt="-6px"
                >
                  ({{ formatCurrency(pricePerDay(item.discountedPrice, item.duration), 2) }}/hari)
                </c-box>
              </c-text>
              <c-box>
                <c-box
                  v-if="item.serviceTarget"
                  mb="12px"
                  :mx="['8px', '20px']"
                >
                  <c-flex
                    v-for="(itemServiceTarget, itemServiceTargetIdx) in item.serviceTarget"
                    :key="itemServiceTargetIdx"
                    flex-direction="row"
                    align-items="center"
                    line-height="150%"
                  >
                    <inline-svg
                      :src="checkList(itemServiceTarget) ? iconCircleCheck : iconCircleClose"
                      class="icon-list"
                      :fill="checkList(itemServiceTarget) ? '#008C81' : '#D32737'"
                    />
                    <c-text
                      :font-size="['14px', '16px']"
                      color="#111111"
                      font-family="Roboto"
                      :ml="['8px', '12px']"
                      text-align="left"
                    >
                      {{ replaceList(itemServiceTarget) }}
                    </c-text>
                  </c-flex>
                </c-box>
              </c-box>
              <c-flex
                :gap="['4px']"
                width="100%"
              >
                <c-box
                  padding="6px 0"
                >
                  <BaseCounter
                    v-model="totalCart[index]"
                    :minimum-value="1"
                  />
                </c-box>
                <BaseButton
                  size="medium"
                  variant="outlined"
                  w="100%"
                  border-radius="1000px"
                  :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
                  left-svg-icon-color="#008C81"
                  left-svg-icon-margin-right="8px"
                  :is-loading="isLoading.addToCart"
                  @click="handleAddToCart(item, index)"
                >
                  Keranjang
                </BaseButton>
                <BaseButton
                  size="medium"
                  variant="contained"
                  w="100%"
                  border-radius="1000px"
                  :is-loading="isLoading.buyNow || isSubmittingCheckOrder"
                  @click="handleCheckOrder(item, index)"
                >
                  Beli Sekarang
                </BaseButton>
              </c-flex>
            </c-box>
          </c-grid>
        </c-box>
      </c-box> -->
    </c-box>

    <!--  MODAL -->
    <ModalInfoDifferentProgram
      :is-open="isOpenModalDifferentProgram"
      @close="isOpenModalDifferentProgram = false"
      @handle-confirm="handleCheckOrder(selectedItem.item, selectedItem.index)"
    />
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import iconCircleClose from '@/assets/icons/icon-circle-close.svg'
import iconCircleCheck from '@/assets/icons/icon-circle-check.svg'
import BaseProgressBar from '@/components/elements/base-progress-bar-2.vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import BaseDivider from '@/components/elements/base-divider.vue'
import { formatCurrency } from '@/utils/format-currency'
// import ModalInfo from '@/components/widgets/modal-info/index.vue'
// import BaseCounter from '@/components/elements/base-counter.vue'
import { reqCompareProduct } from '@/requests/dietela-api/client/compare-product'
import { reqProgramExtend } from '@/requests/dietela-api/general/program-extend'
import ModalInfoDifferentProgram from '@/components/widgets/modal-info-different-program.vue'

export default {
  name: 'ExtendProgram',
  components: {
    ModalInfoDifferentProgram,
    // BaseCounter,
    // ModalInfo,
    BaseDivider,
    BaseProgressBar,
    BaseButton,
    BaseText,
  },
  data() {
    return {
      isLoading: {
        addToCart: false,
        buyNow: false,
      },
      iconCircleClose,
      iconCircleCheck,
      listDataHeader: [],
      // programGrouping: [],
      totalCart: {
        0: 1,
        1: 1,
      },
      isOpenModalDifferentProgram: false,
      isSubmittingCheckOrder: false,
      selectedItem: {
        item: {},
        index: null,
      },
      facilities: [],
      programExtends: [],
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      cartItems: 'cart/items',
      userType: 'userType/userType',
    }),
  },
  mounted() {
    if (this.$route.query?.posthog) {
      this.$posthog.capture(this.$route.query.posthog)
    }
    this.init()
    this.triggerUpdateUserType()
  },
  methods: {
    formatCurrency,
    ...mapActions({
      reqProgramsExtendHeader: 'clients/reqProgramsExtendHeader',
      // reqProgramsExtendList: 'clients/reqProgramsExtendList',
      onUpdateCart: 'cart/onUpdateCart',
      list: 'cart/list',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
    }),
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    pricePerDay(price, duration) {
      return Math.floor((price / duration))
    },
    // checkList(item) {
    //   if (item.includes('[x]')) {
    //     return 0
    //   }
    //   return 1
    // },
    // replaceList(item) {
    //   return item.replace('[v]', '').replace('[x]', '')
    // },
    handleLearnMore() {
      this.$scrollTo('#comparation', 500, {
        offset: -70,
      })
    },
    async handleAddToCart(item, idx) {
      if (!this.isAuthenticated) return this.$router.push({ name: 'auth', query: { tab: 'login' } })
      this.isLoading.addToCart = true

      try {
        let params = {
          productServiceId: item.id,
          extend: +this.totalCart[idx],
        }
        window.fbq('track', 'AddToCart')
        await this.onUpdateCart(params)
        await this.list()
        // const selectCartItem = tes2?.filter((v) => v.productService?.id === params.productServiceId)?.[0] || {}
        //
        // this.setSelectedCart({
        //   quantity: selectCartItem.quantity,
        //   productService: selectCartItem.productService || {},
        //   _isBuyNow: true,
        // })
        // this.$router.push({ name: 'client.cart.check-order' })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading.addToCart = false
      }
    },
    async handleBuyNow(item, idx) {
      this.isLoading.buyNow = true

      try {
        let params = {
          productServiceId: item.id,
          extend: +this.totalCart[idx],
        }
        await this.onUpdateCart(params)
        const tes2 = await this.list()
        const selectCartItem = tes2?.filter((v) => v.productService?.id === params.productServiceId)?.[0] || {}

        const selectedCart = {
          quantity: +this.totalCart[idx],
          productService: selectCartItem.productService || {},
          _isBuyNow: true,
        }
        this.setSelectedCart(selectedCart)

        // POSTHOG capture `plan purchased`
        this.$posthog.capture('plan purchased', {
          userType: this.userType ?? 'not-registered',
          program: this.selectedCart,
        })

        this.$router.push({ name: 'client.cart.check-order' })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading.buyNow = false
      }
      // this.isLoading.buyNow = false
    },
    async handleCheckOrder(item, idx) {
      this.isSubmittingCheckOrder = true
      const showPopupInfo = await reqCompareProduct(
        this.$store.getters.axios,
        item?.id,
      ).then((res) => res.data?.data?.isPopUp).finally(() => {
        this.isSubmittingCheckOrder = false
      })
      if (showPopupInfo) {
        this.isOpenModalDifferentProgram = true
        return
      }
      window.fbq('track', 'BuyNow')
      this.handleBuyNow(item, idx)
    },
    async init() {
      const res = await this.reqProgramsExtendHeader()
      this.listDataHeader = res?.data || []
      // const res1 = await this.reqProgramsExtendList()
      // this.programGrouping = res1.data
      reqProgramExtend.comparation(this.$store.getters.axios)
        .then((res) => {
          this.programExtends = res.data?.data?.result
          const dataFacilities = res.data?.data?.facilities.map(
            (item) => item?.facility,
          ) || []
          this.facilities = dataFacilities
        })
    },
    getFacilityValue(label, data) {
      return data?.find(it => it?.productFacilities?.facility === label)?.value
    },
    isCheckList(value) {
      if (value && value?.includes('[x]') || value?.includes('[v]')) {
        return 1
      }
      return 0
    },
    checkIcon(value) {
      if (value && value?.includes('[x]')) {
        return 0
      }
      return 1
    },
  },
}
</script>

<style scoped>
.table-comparation {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  overflow: hidden;
  border-radius: 8px;
}
.icon-list {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .icon-list {
    width: 20px;
    height: 20px;
  }
}
</style>