var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-text', _vm._g(_vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: _vm.buttonStylesVChakra,
      expression: "buttonStylesVChakra"
    }]
  }, 'c-text', _vm.$attrs, false), _vm.$listeners), [_c('c-box', {
    attrs: {
      "as": "span",
      "d": "inline-block",
      "text-decoration": "inherit"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._t("default", function () {
    return [_vm._v("Text")];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }