<template>
  <BaseModal
    :is-open="isOpen"
    :close-on-overlay-click="false"
    :with-button-close="false"
  >
    <template #header>
      <c-box
        display="flex"
        justify-content="center"
        margin="24px 0 0 0"
      >
        <CImage
          size="150px"
          object-fit="cover"
          :src="require('@/assets/images/bg-1.jpeg')"
          alt="Image Confirmation"
        />
      </c-box>
    </template>
    <template #body>
      <c-box
        display="flex"
        flex-direction="column"
        align-items="center"
        px="24px"
        py="16px"
        text-align="center"
        max-width="500px"
        mx="auto"
        mb="8px"
      >
        <BaseText
          size-mobile="16px"
          size-desktop="20px"
          mb="16px"
          color="primary.400"
        >
          Pembelian Program Berbeda
        </BaseText>
        <c-text
          font-weight="400"
          :font-size="['14px', '16px']"
          color="#555555"
        >
          Program ini akan dimulai setelah program aktif saat ini atau program extend lainnya selesai. Ahli Gizi terpilih akan diteruskan pada program barumu.
        </c-text>
      </c-box>
    </template>
    <template #footer>
      <c-box
        width="100%"
        padding-left="24px"
        padding-right="24px"
        margin="24px 0"
      >
        <c-flex gap="8px">
          <BaseButton
            color="danger"
            rounded="1000px"
            width="100%"
            @click="close"
          >
            Batal
          </BaseButton>
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="handleConfirm"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </c-box>
    </template>
  </BaseModal>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import { CBox, CFlex, CImage, CText } from '@chakra-ui/vue'

export default {
  name: 'ModalInfoDifferentProgram',
  components: { BaseText, BaseModal, BaseButton, CText, CBox, CImage, CFlex },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'handle-confirm'],
  methods: {
    close() {
      this.$emit('close')
    },
    handleConfirm() {
      this.$emit('handle-confirm')
    },
  },
}
</script>
