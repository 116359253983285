const options = {
  default: (number) => `Rp${number},-`, // Rp609.900,-
  1: (number) => `Rp${number}`, // Rp609.900
  2: (number) => `Rp. ${number}`, // Rp. 609.900
  3: (number) => `Rp ${number}`, // Rp 609.900
  4: (number) => `${number}`, // 609.900
}

export const formatCurrency = (value, type = 'default') => {
  const number = new Intl.NumberFormat('id-ID').format(value)
  return options[type](number)
}