<!-- INFO: support all v-chakra binding property-->

<template>
  <c-button
    v-bind="{
      ...buttonStyles,
      ...$attrs, // can override v-chakra styling
    }"
    :disabled="disabled || isLoading"
    :is-loading="isLoading"
    :variant-color="color_"
    :variant="variant_"
    :loading-text="loadingText_"
    :border="variant_ === 'outline' ? '2px solid' : 'none'"
    v-on="$listeners"
  >
    <c-text
      v-if="leftSvgIcon"
      as="span"
      :min-height="['20px', '25px']"
      :min-width="['20px', '25px']"
      :height="['20px', '25px']"
      :width="['20px', '25px']"
      :margin-right="leftSvgIconMarginRight"
    >
      <inline-svg
        :src="leftSvgIcon"
        height="100%"
        width="100%"
        :fill="leftSvgIconColor || 'black'"
      />
    </c-text>
    <slot>Button</slot>
    <c-text
      v-if="rightSvgIcon"
      as="span"
      :min-height="['20px', '25px']"
      :min-width="['20px', '25px']"
      :height="['20px', '25px']"
      :width="['20px', '25px']"
      :margin-left="rightSvgIconNoMargin ? 'none' : ['8px', '16px']"
    >
      <inline-svg
        :src="rightSvgIcon"
        height="100%"
        width="100%"
        :fill="rightSvgIconColor || 'black'"
      />
    </c-text>
  </c-button>
</template>

<script>
const SCREEN_TYPE = {
  'mobile-only': 'mobile-only',
  'desktop-only': 'desktop-only',
  'both': 'both',
}

const MOBILE_STYLES = {
  small: {
    'h': '34px',
    'padding': '0 12px 0 12px',
    'font-family': 'Roboto',
    'font-size': '14px',
    'font-weight': '500',
    'letter-spacing': '0em',
    'border-radius': '16px',
  },
  medium: {
    'h': '44px',
    'padding': '0 12px 0 12px',
    'font-family': 'Roboto',
    'font-size': '14px',
    'font-weight': '500',
    'letter-spacing': '0em',
    'border-radius': '16px',
  },
  large: {
    'h': '48px',
    'padding': '0 12px 0 12px',
    'font-family': 'Roboto',
    'font-size': '14px',
    'font-weight': '500',
    'letter-spacing': '0em',
    'border-radius': '16px',
  },
}

const DESKTOP_STYLES = {
  small: {
    'h': '50px',
    'padding': '0 20px',
    'font-family': 'Roboto',
    'font-size': '18px',
    'font-weight': '500',
    'letter-spacing': '0em',
    'border-radius': '1000px',
  },
  medium: {
    'h': '60px',
    'padding': '0 20px',
    'font-family': 'Roboto',
    'font-size': '18px',
    'font-weight': '500',
    'letter-spacing': '0em',
    'border-radius': '1000px',
  },
  large: {
    'h': '70px',
    'padding': '0 20px',
    'font-family': 'Roboto',
    'font-size': '18px',
    'font-weight': '500',
    'letter-spacing': '0em',
    'border-radius': '1000px',
  },
}

const DESKTOP_AND_MOBILE_STYLES = {
  small: {
    'h': [MOBILE_STYLES.small.h, DESKTOP_STYLES.small.h],
    'padding': [MOBILE_STYLES.small.padding, DESKTOP_STYLES.small.padding],
    'font-family': [MOBILE_STYLES.small['font-family'], DESKTOP_STYLES.small['font-family']],
    'font-size': [MOBILE_STYLES.small['font-size'], DESKTOP_STYLES.small['font-size']],
    'font-weight': [MOBILE_STYLES.small['font-weight'], DESKTOP_STYLES.small['font-weight']],
    'letter-spacing': [MOBILE_STYLES.small['letter-spacing'], DESKTOP_STYLES.small['letter-spacing']],
    'border-radius': [MOBILE_STYLES.small['border-radius'], DESKTOP_STYLES.small['border-radius']],
  },
  medium: {
    'h': [MOBILE_STYLES.medium.h, DESKTOP_STYLES.medium.h],
    'padding': [MOBILE_STYLES.medium.padding, DESKTOP_STYLES.medium.padding],
    'font-family': [MOBILE_STYLES.medium['font-family'], DESKTOP_STYLES.medium['font-family']],
    'font-size': [MOBILE_STYLES.medium['font-size'], DESKTOP_STYLES.medium['font-size']],
    'font-weight': [MOBILE_STYLES.medium['font-weight'], DESKTOP_STYLES.medium['font-weight']],
    'letter-spacing': [MOBILE_STYLES.medium['letter-spacing'], DESKTOP_STYLES.medium['letter-spacing']],
    'border-radius': [MOBILE_STYLES.medium['border-radius'], DESKTOP_STYLES.medium['border-radius']],
  },
  large: {
    'h': [MOBILE_STYLES.large.h, DESKTOP_STYLES.large.h],
    'padding': [MOBILE_STYLES.large.padding, DESKTOP_STYLES.large.padding],
    'font-family': [MOBILE_STYLES.large['font-family'], DESKTOP_STYLES.large['font-family']],
    'font-size': [MOBILE_STYLES.large['font-size'], DESKTOP_STYLES.large['font-size']],
    'font-weight': [MOBILE_STYLES.large['font-weight'], DESKTOP_STYLES.large['font-weight']],
    'letter-spacing': [MOBILE_STYLES.large['letter-spacing'], DESKTOP_STYLES.large['letter-spacing']],
    'border-radius': [MOBILE_STYLES.large['border-radius'], DESKTOP_STYLES.large['border-radius']],
  },
}

// import { CBox, CButton, CText } from '@chakra-ui/vue'

export default {
  name: 'BaseButton',
  inheritAttrs: false,
  props: {
    textColor: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    loadingText: {
      type: String,
      default: '',
    },
    screen: {
      type: String,
      default: '',
    },
    leftSvgIcon: {
      type: [String, {}],
      default: '',
    },
    rightSvgIcon: {
      type: [String, {}],
      default: '',
    },
    rightSvgIconNoMargin: {
      type: Boolean,
      default: false,
    },
    leftSvgIconColor: {
      type: String,
      default: '',
    },
    rightSvgIconColor: {
      type: String,
      default: '',
    },
    leftSvgIconMarginRight: {
      type: [Array, String],
      default: () => ['8px', '16px'],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    buttonStyles() {
      switch (SCREEN_TYPE[this.screen]) {
        case SCREEN_TYPE['mobile-only']:
          return MOBILE_STYLES[this.size_]
        case SCREEN_TYPE['desktop-only']:
          return DESKTOP_STYLES[this.size_]
        case SCREEN_TYPE['both']:
        default:
          return DESKTOP_AND_MOBILE_STYLES[this.size_]
      }
    },
    color_() {
      switch (this.color) {
        case 'primary':
        case 'secondary':
        case 'danger':
        case 'warning':
        // case 'success':
        // case 'info':
          return this.color
        default:
          return 'primary'
      }
    },
    size_() {
      switch (this.size) {
        case 'small':
        case 'medium':
        case 'large':
          return this.size
        default:
          return 'small'
      }
    },
    variant_() {
      switch (this.variant) {
        case 'outlined':
          return 'outline'
        case 'contained':
          return 'solid'
        case 'text':
          return 'ghost'
        default:
          return 'solid'
      }
    },
    loadingText_() {
      if (this.isLoadingText) {
        if (this.loadingText) {
          return this.loadingText
        }
        const slotText = this.$slots?.default?.[0]?.text || ''
        if (slotText) {
          return slotText
        }
        return 'Loading...'
      }
      return null
    },
  },
}
</script>
