<!-- INFO: support all v-chakra binding property-->

<template>
  <c-text
    v-chakra="buttonStylesVChakra"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <c-box
      as="span"
      d="inline-block"
      text-decoration="inherit"
      @click="$emit('click')"
    >
      <slot>Text</slot>
    </c-box>
  </c-text>
</template>

<script>
const MOBILE_STYLES = {
  '36px': {
    'fontFamily': 'Roboto, sans-serif',
    'fontSize': '36px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '20px': {
    'fontFamily': 'Roboto',
    'fontSize': '20px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '18px-2': {
    'fontFamily': 'Roboto',
    'fontSize': '18px',
    'fontWeight': '900',
    'letterSpacing': '0em',
  },
  '18px': {
    'fontFamily': 'Roboto',
    'fontSize': '18px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '16px-2': {
    'fontFamily': 'Roboto',
    'fontSize': '16px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '16px': {
    'fontFamily': 'Roboto',
    'fontSize': '16px',
    'fontWeight': '500',
    'letterSpacing': '0em',
  },
  '14px': {
    'fontFamily': 'Roboto',
    'fontSize': '14px',
    'fontWeight': '500',
    'letterSpacing': '0em',
  },
  '14px-2': {
    'fontFamily': 'Roboto',
    'fontSize': '14px',
    'fontWeight': '400',
    'letterSpacing': '0em',
  },
  '14px-3': {
    'fontFamily': 'Roboto',
    'fontSize': '14px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '12px': {
    'fontFamily': 'Roboto',
    'fontSize': '12px',
    'fontWeight': '400',
    'letterSpacing': '0em',
  },
  '12px-2': {
    'fontFamily': 'Roboto',
    'fontSize': '12px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '10px': {
    'fontFamily': 'Roboto',
    'fontSize': '10px',
    'fontWeight': '400',
    'letterSpacing': '0em',
  },
}

const DESKTOP_STYLES = {
  '36px-2': {
    'fontFamily': 'Roboto, sans-serif',
    'fontSize': '36px',
    'fontWeight': '900',
    'letterSpacing': '0em',
  },
  '36px': {
    'fontFamily': 'Roboto, sans-serif',
    'fontSize': '36px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '28px': {
    'fontFamily': 'Roboto, sans-serif',
    'fontSize': '28px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '20px': {
    'fontFamily': 'Roboto, sans-serif',
    'fontSize': '20px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '18px': {
    'fontFamily': 'Roboto, sans-serif',
    'fontSize': '18px',
    'fontWeight': '500',
    'letterSpacing': '0em',
  },
  '18px-2': {
    'fontFamily': 'Roboto, sans-serif',
    'fontSize': '18px',
    'fontWeight': '600',
    'letterSpacing': '0em',
  },
  '16px': {
    'fontFamily': 'Roboto',
    'fontSize': '16px',
    'fontWeight': '400',
    'letterSpacing': '0em',
  },
  '16px-2': {
    'fontFamily': 'Roboto',
    'fontSize': '16px',
    'fontWeight': '700',
    'letterSpacing': '0em',
  },
  '16px-3': {
    'fontFamily': 'Roboto',
    'fontSize': '16px',
    'fontWeight': '500',
    'letterSpacing': '0em',
  },
  '14px': {
    'fontFamily': 'Roboto',
    'fontSize': '14px',
    'fontWeight': '500',
    'letterSpacing': '0em',
  },
  '14px-2': {
    'fontFamily': 'Roboto',
    'fontSize': '14px',
    'fontWeight': '400',
    'letterSpacing': '0em',
  },
  '12px': {
    'fontFamily': 'Roboto',
    'fontSize': '12px',
    'fontWeight': '400',
    'letterSpacing': '0em',
  },
}
export default {
  name: 'BaseText',
  inheritAttrs: false,
  props: {
    sizeDesktop: {
      type: String,
      required: true,
    },
    sizeMobile: {
      type: String,
      required: true,
    },
  },
  computed: {

    buttonStylesVChakra() {
      if (this.$attrs.as === 'a') {
        return {
          'cursor': 'pointer',
          ':hover': {
            'textDecoration': 'underline',
          },
          ...MOBILE_STYLES[this.sizeMobile],
          '@media (min-width: 768px)': {
            ...DESKTOP_STYLES[this.sizeDesktop],
          },
        }
      }
      return {
        ...MOBILE_STYLES[this.sizeMobile],
        '@media (min-width: 768px)': {
          ...DESKTOP_STYLES[this.sizeDesktop],
        },
      }
    },
  },
}
</script>
