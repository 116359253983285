var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-button', _vm._g(_vm._b({
    attrs: {
      "disabled": _vm.disabled || _vm.isLoading,
      "is-loading": _vm.isLoading,
      "variant-color": _vm.color_,
      "variant": _vm.variant_,
      "loading-text": _vm.loadingText_,
      "border": _vm.variant_ === 'outline' ? '2px solid' : 'none'
    }
  }, 'c-button', Object.assign({}, _vm.buttonStyles, _vm.$attrs), false), _vm.$listeners), [_vm.leftSvgIcon ? _c('c-text', {
    attrs: {
      "as": "span",
      "min-height": ['20px', '25px'],
      "min-width": ['20px', '25px'],
      "height": ['20px', '25px'],
      "width": ['20px', '25px'],
      "margin-right": _vm.leftSvgIconMarginRight
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.leftSvgIcon,
      "height": "100%",
      "width": "100%",
      "fill": _vm.leftSvgIconColor || 'black'
    }
  })], 1) : _vm._e(), _vm._t("default", function () {
    return [_vm._v("Button")];
  }), _vm.rightSvgIcon ? _c('c-text', {
    attrs: {
      "as": "span",
      "min-height": ['20px', '25px'],
      "min-width": ['20px', '25px'],
      "height": ['20px', '25px'],
      "width": ['20px', '25px'],
      "margin-left": _vm.rightSvgIconNoMargin ? 'none' : ['8px', '16px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.rightSvgIcon,
      "height": "100%",
      "width": "100%",
      "fill": _vm.rightSvgIconColor || 'black'
    }
  })], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }