var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "background-color": ['#F2F2F2', '#FFF'],
      "padding-bottom": "16px",
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "background-color": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "min-height": ['calc(100vh - 62px)', '74vh'],
      "width": "100%",
      "max-width": "1270px"
    }
  }, [_c('c-box', {
    attrs: {
      "padding": ['0 0 0 0', '16px 16px 0 16px']
    }
  }, [_c('c-box', {
    attrs: {
      "padding": ['32px 20px', '32px 83px'],
      "display": "flex",
      "flex-direction": ['column', 'row'],
      "gap": ['8px', '70px'],
      "justify-content": "center",
      "align-items": "center",
      "border-radius": ['0', '12px'],
      "position": "relative",
      "overflow": "hidden"
    }
  }, [_c('c-image', {
    attrs: {
      "position": "absolute",
      "src": "https://ik.imagekit.io/dietela/pwa_webp/extend_program/bg_extendpage.webp?updatedAt=1686540820606",
      "width": "100%",
      "height": "100%",
      "object-fit": "cover",
      "transform": "scale(1.4)",
      "opacity": ['0.2', '0.4']
    }
  }), _c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "justify-content": ['center'],
      "align-items": ['stretch', 'flex-start'],
      "position": "relative"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px-2",
      "size-desktop": "36px-2",
      "color": "#005A64",
      "font-weight": "900",
      "white-space": "nowrap",
      "text-align": ['center', 'left']
    }
  }, [_vm._v(" LANJUTKAN "), _c('br'), _vm._v(" PROGRAM DIETELA "), _c('br'), _vm._v(" HANYA 8000an/HARI ")]), _c('c-box', {
    attrs: {
      "position": "relative",
      "margin-bottom": "3px",
      "width": "150%",
      "left": "50%",
      "transform": "translateX(-50%)",
      "display": ['block', 'none']
    }
  }, [_c('BaseDivider', {
    attrs: {
      "border-color": "#005A64"
    }
  })], 1), _c('BaseButton', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        color: '#008C81 !important',
        fontWeight: '600'
      },
      expression: "{\n              color: '#008C81 !important',\n              fontWeight: '600',\n            }"
    }],
    attrs: {
      "size": "medium",
      "border-radius": "1000px",
      "padding": "0px 32px",
      "margin-top": ['0', '16px'],
      "variant": "solid",
      "color": "secondary",
      "background-color": "secondary.400"
    },
    on: {
      "click": _vm.handleLearnMore
    }
  }, [_vm._v(" Pelajari lebih lanjut ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "position": "relative"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "36px",
      "text-align": "center",
      "color": "primary.500",
      "border-bottom": "1px solid #005A64"
    }
  }, [_vm._v(" Dietela Terbukti ")]), _c('c-box', {
    attrs: {
      "margin-top": ['10px', '20px']
    }
  }, _vm._l(_vm.listDataHeader, function (dataHeader, dataHeaderIdx) {
    return _c('c-box', {
      key: dataHeaderIdx,
      attrs: {
        "margin-top": dataHeaderIdx === 0 ? '0' : '16px'
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "14px",
        "size-desktop": "18px"
      }
    }, [_vm._v(" " + _vm._s(dataHeader.label) + " ")]), _c('BaseProgressBar', {
      attrs: {
        "margin-top": "8px",
        "initial-value": "0",
        "value": dataHeader.value * 100,
        "delay": dataHeaderIdx * 0.1,
        "text": dataHeader.description,
        "background-color": "#F2F2F2",
        "border": "1px solid #888"
      }
    })], 1);
  }), 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "padding": ['16px 8px', '16px 16px 0 16px']
    }
  }, [_c('c-box', {
    attrs: {
      "background-color": "#FFFCDD",
      "pos": "relative",
      "d": "flex",
      "border-top-left-radius": "4px",
      "border-top-right-radius": "4px",
      "border-bottom-left-radius": ['20px', '100px'],
      "border-bottom-right-radius": ['10px', '40px'],
      "overflow": "hidden"
    }
  }, [_c('c-box', {
    attrs: {
      "pos": "absolute",
      "background-color": "#FDF9BC",
      "border-radius": "100%",
      "h": "500px",
      "w": "500px",
      "top": ['-250px', 'unset'],
      "bottom": ['unset', '2.5rem'],
      "left": ['70px', '5rem'],
      "z-index": "1"
    }
  }), _c('c-box', {
    attrs: {
      "d": "flex",
      "max-width": "920px",
      "py": ['1rem', '1.5rem'],
      "px": ['1rem', '0'],
      "mx": "auto",
      "z-index": "2"
    }
  }, [_c('c-image', {
    attrs: {
      "d": ['none', 'block'],
      "src": "https://ik.imagekit.io/dietela/pwa_webp/extend_program/lanjutkanprogram.webp?updatedAt=1686540820023",
      "h": "250px",
      "flex-shrink": "0",
      "mr": "1rem"
    }
  }), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-direction": "column",
      "justify-content": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px', '28px'],
      "font-weight": "700",
      "color": "#008C81",
      "mb": "1rem",
      "text-align": ['center', 'left']
    }
  }, [_vm._v(" KENAPA LANJUTKAN LAYANAN? ")]), _c('c-image', {
    attrs: {
      "d": ['block', 'none'],
      "src": "https://ik.imagekit.io/dietela/pwa_webp/extend_program/lanjutkanprogram.webp?updatedAt=1686540820023",
      "h": "150px",
      "mb": "1rem",
      "mx": "auto"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "mb": "1rem"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "span",
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" Support system personal. ")]), _vm._v(" Penelitian menunjukkan bahwa dukungan dari tenaga kesehatan adalah salah satu faktor terpenting dalam mempertahankan hasil diet, untuk mencegah kamu kembali ke kondisi sebelumnya. ")], 1), _c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": "400"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "span",
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" Harga khusus, fasilitas banyak. ")]), _vm._v(" Sekali kamu berlangganan lebih dari 1 bulan, kamu akan mendapatkan harga khusus dengan fasilitas layanan Ahli Gizi, olahraga dan webinar Dietela. ")], 1)], 1)], 1)], 1)], 1), _vm.programExtends.length > 0 ? _c('c-box', {
    staticClass: "tableGsap",
    attrs: {
      "id": "comparation",
      "width": "100%",
      "max-width": "1100px",
      "px": ['10px', '0'],
      "background-color": "#FFFFFF",
      "py": ['10px', '20px'],
      "mx": "auto"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "28px",
      "text-align": "center",
      "margin-bottom": ['10px', '20px']
    }
  }, [_vm._v(" Pilih Program Sesuai Kebutuhanmu ")]), _c('c-box', {
    staticClass: "table-comparation",
    attrs: {
      "pos": "relative",
      "display": ['none', 'table'],
      "as": "table",
      "width": "100%",
      "z-index": "3"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "thead",
      "color": "#FFF",
      "background-color": "primary.400",
      "font-weight": "700",
      "border-radius": "8px"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:first-child': {
          borderLeft: '1px solid #008C81'
        },
        '>:last-child': {
          borderRight: '1px solid #008C81'
        }
      },
      expression: "{\n              '>:first-child': {\n                borderLeft: '1px solid #008C81',\n              },\n              '>:last-child': {\n                borderRight: '1px solid #008C81',\n              },\n            }"
    }],
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "th",
      "text-align": "center",
      "font-size": "24px",
      "py": "1rem"
    }
  }, [_vm._v(" Fasilitas Layanan ")]), _vm._l(_vm.programExtends, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "as": "th",
        "flex-direction": "column",
        "justify": "center",
        "py": "1rem",
        "width": "315px"
      }
    }, [_c('c-box', {
      attrs: {
        "border-left": "1px solid #005A64",
        "px": "8px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "20px",
        "margin-bottom": "8px",
        "font-weight": "700"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.programsService) + " " + _vm._s(item === null || item === void 0 ? void 0 : item.duration) + " Hari ")]), _c('c-box', {
      attrs: {
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "gap": "10px",
        "border-radius": "8px",
        "background-color": "primary.100"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "700",
        "text-decoration": "line-through",
        "color": "danger.400"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.price, 2)) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "32px",
        "font-weight": "700",
        "color": "primary.600"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.discountedPrice, 2)) + " ")])], 1), _c('c-box', {
      attrs: {
        "font-size": "16px",
        "font-weight": "400",
        "mt": "8px"
      }
    }, [_vm._v(" (" + _vm._s(_vm.formatCurrency(_vm.pricePerDay(item.discountedPrice, item.duration), 2)) + "/hari) ")])], 1)], 1);
  })], 2)], 1), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> tr': {
          borderLeft: '1px solid #008C81',
          borderRight: '1px solid #008C81'
        }
      },
      expression: "{\n            '> tr': {\n              borderLeft: '1px solid #008C81',\n              borderRight: '1px solid #008C81',\n            },\n          }"
    }],
    attrs: {
      "as": "tbody",
      "background-color": "#FFF",
      "color": "#000",
      "font-size": ['12px', '16px']
    }
  }, [_vm._l(_vm.facilities, function (facility, i) {
    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '>:not(:last-child):not(:first-child)': {
            borderRight: '1px solid #C4C4C4',
            borderLeft: '1px solid #C4C4C4'
          }
        },
        expression: "{\n              '>:not(:last-child):not(:first-child)': {\n                borderRight: '1px solid #C4C4C4',\n                borderLeft: '1px solid #C4C4C4',\n              },\n            }"
      }],
      key: "tr-".concat(i),
      attrs: {
        "as": "tr"
      }
    }, _vm._l(_vm.programExtends.length + 1, function (k) {
      var _vm$programExtends, _vm$programExtends2, _vm$programExtends3, _vm$programExtends4;

      return _c('c-box', {
        key: "td-".concat(i, "-").concat(k),
        attrs: {
          "as": "td",
          "px": "1rem",
          "text-align": k === 1 ? 'left' : 'center'
        }
      }, [k === 1 ? _c('c-box', {
        attrs: {
          "w": "100%",
          "py": "1rem",
          "px": k === 1 ? '12px' : '0',
          "border-bottom": "1px solid #C4C4C4"
        }
      }, [_vm._v(" " + _vm._s(facility) + " ")]) : _c('c-box', {
        attrs: {
          "w": "100%",
          "py": "1rem",
          "border-bottom": "1px solid #C4C4C4",
          "display": "flex",
          "justify-content": "center"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility, (_vm$programExtends = _vm.programExtends[k - 2]) === null || _vm$programExtends === void 0 ? void 0 : _vm$programExtends.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programExtends2 = _vm.programExtends[k - 2]) === null || _vm$programExtends2 === void 0 ? void 0 : _vm$programExtends2.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programExtends3 = _vm.programExtends[k - 2]) === null || _vm$programExtends3 === void 0 ? void 0 : _vm$programExtends3.productFacilitiesComparation)) ? '#008C81' : '#D32737'
        }
      }) : _c('c-box', [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility, (_vm$programExtends4 = _vm.programExtends[k - 2]) === null || _vm$programExtends4 === void 0 ? void 0 : _vm$programExtends4.productFacilitiesComparation)) + " ")])], 1)], 1);
    }), 1);
  }), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:not(:last-child):not(:first-child)': {
          borderRight: '1px solid #C4C4C4',
          borderLeft: '1px solid #C4C4C4'
        }
      },
      expression: "{\n              '>:not(:last-child):not(:first-child)': {\n                borderRight: '1px solid #C4C4C4',\n                borderLeft: '1px solid #C4C4C4',\n              },\n            }"
    }],
    attrs: {
      "as": "tr",
      "border-bottom": "1px solid #008C81"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "text-align": "center"
    }
  }), _vm._l(_vm.programExtends, function (item, index) {
    return _c('c-box', {
      key: "buy-".concat(item === null || item === void 0 ? void 0 : item.id),
      attrs: {
        "as": "td",
        "px": "1rem",
        "py": "1rem"
      }
    }, [_c('c-flex', {
      attrs: {
        "justify-content": "space-between",
        "align-items": "center",
        "gap": "12px"
      }
    }, [_c('c-button', {
      attrs: {
        "variant": "outline",
        "variant-color": "primary",
        "height": "50px",
        "min-width": "50px",
        "padding": "0px",
        "border-radius": "12px",
        "is-loading": _vm.isLoading.addToCart
      },
      on: {
        "click": function click($event) {
          return _vm.handleAddToCart(item, index);
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-cart.svg'),
        "height": "20px",
        "width": "20px",
        "fill": "#008C81"
      }
    })], 1), _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            color: 'primary.500',
            background: 'secondary.500'
          },
          ':active': {
            color: 'white',
            background: 'secondary.600'
          }
        },
        expression: "{\n                    ':hover': {\n                      color:'primary.500',\n                      background:'secondary.500',\n                    },\n                    ':active': {\n                      color: 'white',\n                      background: 'secondary.600',\n                    },\n                  }"
      }],
      attrs: {
        "width": "100%",
        "variant": "solid",
        "color": "primary.400",
        "background": "secondary.400",
        "border-radius": "60px",
        "height": "50px",
        "is-loading": _vm.isLoading.buyNow || _vm.isSubmittingCheckOrder
      },
      on: {
        "click": function click($event) {
          return _vm.handleCheckOrder(item, index);
        }
      }
    }, [_vm._v(" Beli Sekarang ")])], 1)], 1);
  })], 2)], 2)], 1), _c('c-box', {
    attrs: {
      "pos": "relative",
      "display": ['block', 'none'],
      "z-index": "3",
      "width": "100%"
    }
  }, [_c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:first-child) > div': {
          borderLeft: '1px solid #005A64'
        }
      },
      expression: "{\n            '> :not(:first-child) > div': {\n              borderLeft:'1px solid #005A64',\n            },\n          }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(_vm.programExtends.length, ", 1fr)"),
      "background-color": "primary.400",
      "color": "#FFF",
      "font-size": "14px",
      "font-weight": "500",
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px",
      "border": "1px solid #008C81"
    }
  }, _vm._l(_vm.programExtends, function (item) {
    return _c('c-box', {
      key: item === null || item === void 0 ? void 0 : item.programsService,
      attrs: {
        "w": "100%",
        "py": "6px"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "flex-direction": "column",
        "align-items": "center",
        "px": "8px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "14px",
        "margin-bottom": "8px",
        "font-weight": "500",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.programsService) + " " + _vm._s(item === null || item === void 0 ? void 0 : item.duration) + " Hari ")]), _c('c-box', {
      attrs: {
        "width": "100%",
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "gap": "0px",
        "border-radius": "8px",
        "background-color": "primary.100",
        "padding-top": "4px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "12px",
        "font-weight": "700",
        "text-decoration": "line-through",
        "color": "danger.400"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.price, 2)) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "20px",
        "font-weight": "700",
        "color": "primary.600"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.discountedPrice, 2)) + " ")])], 1), _c('c-box', {
      attrs: {
        "font-size": "10px",
        "font-weight": "400",
        "mt": "4px"
      }
    }, [_vm._v(" (" + _vm._s(_vm.formatCurrency(_vm.pricePerDay(item.discountedPrice, item.duration), 2)) + "/hari) ")])], 1)], 1);
  }), 1), _c('c-box', {
    attrs: {
      "p": "8px",
      "color": "#000",
      "font-size": "12px",
      "font-weight": "400",
      "background-color": "#FFF",
      "border-bottom-left-radius": "8px",
      "border-bottom-right-radius": "8px",
      "border-left": "1px solid #008C81",
      "border-right": "1px solid #008C81",
      "border-bottom": "1px solid #008C81"
    }
  }, [_vm._l(_vm.facilities, function (facility, index) {
    return _c('c-box', {
      key: "facility-".concat(index),
      attrs: {
        "mb": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "p": "6px 12px",
        "background-color": "primary.50",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(facility) + " ")]), _c('c-grid', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '> :not(:first-child)': {
            borderLeft: '1px solid #C4C4C4'
          }
        },
        expression: "{\n                '> :not(:first-child)': {\n                  borderLeft:'1px solid #C4C4C4',\n                },\n              }"
      }],
      attrs: {
        "w": "100%",
        "template-columns": "repeat(".concat(_vm.programExtends.length, ", 1fr)")
      }
    }, _vm._l(_vm.programExtends.length, function (j) {
      var _vm$programExtends5, _vm$programExtends6, _vm$programExtends7, _vm$programExtends8;

      return _c('c-flex', {
        key: j,
        attrs: {
          "w": "100%",
          "py": "4px",
          "flex-direction": "column",
          "align-items": "center"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility, (_vm$programExtends5 = _vm.programExtends[j - 1]) === null || _vm$programExtends5 === void 0 ? void 0 : _vm$programExtends5.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programExtends6 = _vm.programExtends[j - 1]) === null || _vm$programExtends6 === void 0 ? void 0 : _vm$programExtends6.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$programExtends7 = _vm.programExtends[j - 1]) === null || _vm$programExtends7 === void 0 ? void 0 : _vm$programExtends7.productFacilitiesComparation)) ? '#008C81' : '#D32737'
        }
      }) : _c('c-box', [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility, (_vm$programExtends8 = _vm.programExtends[j - 1]) === null || _vm$programExtends8 === void 0 ? void 0 : _vm$programExtends8.productFacilitiesComparation)) + " ")])], 1);
    }), 1)], 1);
  }), _c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:first-child)': {
          borderLeft: '1px solid #C4C4C4'
        }
      },
      expression: "{\n              '> :not(:first-child)': {\n                borderLeft:'1px solid #C4C4C4',\n              },\n            }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(_vm.programExtends.length, ", 1fr)")
    }
  }, _vm._l(_vm.programExtends, function (item, index) {
    return _c('c-flex', {
      key: index,
      attrs: {
        "width": "100%",
        "justify-content": "space-between",
        "align-items": "center",
        "p": "6px",
        "gap": "6px"
      }
    }, [_c('c-button', {
      attrs: {
        "variant": "outline",
        "variant-color": "primary",
        "height": "34px",
        "min-width": "34px",
        "padding": "0px",
        "border-radius": "12px",
        "is-loading": _vm.isLoading.addToCart
      },
      on: {
        "click": function click($event) {
          return _vm.handleAddToCart(item, index);
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-cart.svg'),
        "height": "18px",
        "width": "18px",
        "fill": "#008C81"
      }
    })], 1), _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            color: 'primary.500',
            background: 'secondary.500'
          },
          ':active': {
            color: 'white',
            background: 'secondary.600'
          }
        },
        expression: "{\n                  ':hover': {\n                    color:'primary.500',\n                    background:'secondary.500',\n                  },\n                  ':active': {\n                    color: 'white',\n                    background: 'secondary.600',\n                  },\n                }"
      }],
      attrs: {
        "flex-grow": "1",
        "width": "100%",
        "variant": "solid",
        "color": "primary.400",
        "background": "secondary.400",
        "border-radius": "16px",
        "height": "34px",
        "font-size": "12px",
        "px": "0",
        "is-loading": _vm.isLoading.buyNow || _vm.isSubmittingCheckOrder
      },
      on: {
        "click": function click($event) {
          return _vm.handleCheckOrder(item, index);
        }
      }
    }, [_vm._v(" Beli Sekarang ")])], 1);
  }), 1)], 2)], 1)], 1) : _vm._e()], 1), _c('ModalInfoDifferentProgram', {
    attrs: {
      "is-open": _vm.isOpenModalDifferentProgram
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalDifferentProgram = false;
      },
      "handle-confirm": function handleConfirm($event) {
        return _vm.handleCheckOrder(_vm.selectedItem.item, _vm.selectedItem.index);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }