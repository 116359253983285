var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', _vm._g(_vm._b({
    attrs: {
      "h": _vm.STYLES[_vm.size].height,
      "rounded": "1000px",
      "width": "100%",
      "background-color": _vm.backgroundColor,
      "border": _vm.border
    }
  }, 'c-box', _vm.$attrs, false), _vm.$listeners), [_c('c-box', {
    attrs: {
      "rounded": "1000px",
      "height": "115%",
      "background-color": "#005A64",
      "width": _vm.valuee + '%',
      "overflow": "hidden",
      "white-space": "nowrap",
      "position": "relative",
      "display": "inline-block",
      "font-family": "Roboto",
      "top": "50%",
      "transform": "translateY(-50%)",
      "font-size": _vm.STYLES[_vm.size].fontSize,
      "font-weight": _vm.STYLES[_vm.size].fontWeight
    }
  }, [_c('c-text', {
    attrs: {
      "color": "#C7F9E3",
      "overflow": "hidden",
      "white-space": "nowrap",
      "text-overflow": "ellipsis",
      "width": "calc(100% - 80px)",
      "display": "inline-block",
      "position": "absolute",
      "top": "50%",
      "left": "30px",
      "transform": "translateY(-50%)"
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]), _c('c-text', {
    attrs: {
      "color": "#C7F9E3",
      "display": "inline-block",
      "position": "absolute",
      "top": "50%",
      "right": "12px",
      "transform": "translateY(-50%)",
      "font-size": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + "% ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }